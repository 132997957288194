
    import Vue from "vue";
    import Component from "vue-class-component";
    import ApiButton from "@/components/ApiButton.vue";
    import MultiDocument from "@/components/MultiDocument.vue";
    import { Watch } from "vue-property-decorator";
    import { Prop } from "vue-property-decorator";
    import { StateChanger } from "vue-infinite-loading";
    import store from "@/store/store";
    import apiClient from "@/stuff/ApiClient";
    import utils from "@/stuff/Utils";
    import fileDownload from "@/stuff/FileDownload";
    import { LookupItem, ILookupItem } from "@/model/LookupItem";
    import { ICommunication, Communication } from "@/model/Communication";
    import { CommunicationSearchParameters } from "@/model/CommunicationSearchParameters";
    import { TriState } from "@/model/Enums";
    import { Document as ModelDocument } from "@/model/Document";

    @Component({
        components: { ApiButton, MultiDocument }
    })
    export default class Communications extends Vue {

        constructor() {
            super();
            // setting parameters here to avoid tripping any watchers...
            this.searchParameters.isClosed = TriState.False;
            this.searchParameters.supplierID = this.supplierID;
            this.searchParameters.isBuyer = TriState.False;
        }

        mounted() {
            store.dispatch("loadCommunicationTypeList");
            store.dispatch("loadUserList");
            // No need to load communications - the infinite loading component will run the search...
        }

        //
        // -- properties
        //

        @Prop({ default: 0 })
        private supplierID!: number;

        private readonly searchParameters = new CommunicationSearchParameters();
        private totalCount = -1;
        private readonly communication = new Communication();
        private communicationList: Array<Communication> = [];

        private get communicationTypeList(): Array<ILookupItem> { return this.$store.state.communicationTypeList; }
        private get communicationTypeSearchOptions(): Array<ILookupItem> { return utils.selectOptions(this.$store.state.communicationTypeList, "Any type..."); }

        private get isClosedSearchOptions(): Array<ILookupItem> { return [
            new LookupItem({ id: TriState.UseDefault, description: "Any", isArchived: false }),
            new LookupItem({ id: TriState.True, description: "Closed", isArchived: false }),
            new LookupItem({ id: TriState.False, description: "Not Closed", isArchived: false })
        ];}

        private envelopeGlyphCss(communication: Communication): string {
            return communication.hasBeenOpened
                ? "far fa-envelope-open fa-2x"
                : "fas fa-envelope fa-2x";
        }

        private getDetailsHtml(communication: Communication): string {
            if(!communication.hasBeenOpened) {
                return "<div class='lightText'>(Please click to open)</div>";
            }
            return `<div class="communicationDetailBox" style="max-height:300px">${communication.details}</div>`;
        }

        private getAttachmentsHtml(communication: Communication): string {
            if(communication.documents.length === 0) return "<span class='badge badge-pill badge-info'>0</span>";
            if(!communication.hasBeenOpened) {
                return `<span class='badge badge-pill badge-danger'>${communication.documents.length}</span>`
            }
            let text = "";
            let delimiter = "";
            for(const document of communication.documents) {
                text += delimiter + "<img height='20' src='" + utils.iconUrl(document) + "' /> <span class='fileName'>" + document.originalFilename  + "</span>";
                delimiter = "<br>";
            }
            return text || "- - -";
        }

        //
        // -- watchers
        //

        @Watch("supplierID")
        onSupplierIdChanged() {
            this.searchParameters.supplierID = this.supplierID;
            this.refreshSearch();
        }

        @Watch("searchParameters.typeID")
        onTypeIdChanged() {
            this.refreshSearch();
        }

        @Watch("searchParameters.isClosed")
        onIsClosedChanged() {
            this.refreshSearch();
        }

        //
        // -- methods
        //

        private async refreshSearch(event?: Event) {
            this.communicationList = [];
            this.totalCount = -1;
            await this.search(event);
        }

        private async search(event?: Event) {
            const response = await apiClient.post("/Api/Communication/Search", this.searchParameters, event);
            if (this.searchParameters.pageNumber === 1) {
                this.totalCount = response.count;
                this.$emit("communicationCountChanged", response.count); // update tab count badge in parent
            }
            // add new 'page' to existing list
            this.communicationList.push(...response.list.map((s: ICommunication) => new Communication(s)));
        }

        async viewCommunication(communicationData: ICommunication) {
            this.communication.update(communicationData);
            // show the dialogue
            this.$bvModal.show("communicationDialogue");
            // refresh from server - and mark as opened
            const serverCommunicationData: ICommunication = await apiClient.get(`api/communication/OpenAndLoad?communicationID=${communicationData.id}&buyerID=0`);
            this.communication.update(serverCommunicationData);
            const listItem = this.communicationList.filter(c => c.id === serverCommunicationData.id);
            if(listItem != null && listItem.length === 1) listItem[0].update(serverCommunicationData);
        }

        private iconUrl(document: ModelDocument): string {
            return utils.iconUrl(document);
        }

        async download(doc: ModelDocument) {
            fileDownload.download(doc);
        }

        async infiniteLoadingHandler(stateChanger: StateChanger) {
            if (this.communicationList.length >= this.totalCount && this.totalCount > -1) {
                stateChanger.complete();
                return;
            }
            this.searchParameters.pageNumber += 1;
            await this.search();
            if (this.communicationList.length >= this.totalCount) {
                stateChanger.complete();
            }
            else {
                stateChanger.loaded();
            }           
        }
    }
