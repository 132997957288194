
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import ApiButton from "@/components/ApiButton.vue";
import apiClient from "@/stuff/ApiClient";
import utils from "@/stuff/Utils";
import fileDownload from "@/stuff/FileDownload";
import { Document as ModelDocument } from "@/model/Document";
import { IFileUploadResponse } from "@/model/IFileUploadResponse";
import { DocumentCategory } from "@/model/Enums";
import * as toastr from "toastr";

@Component({
    components: { ApiButton }
})
export default class FileUpload extends Vue {

    @Prop({ required: true })
    private documents!: Array<ModelDocument>;

    @Prop({ default: false })
    private isReadOnly!: boolean;

    @Prop({ default: true })
    private canDelete!: boolean;

    @Prop({ default: "" })
    private cantDeleteMessage!: string;

    @Prop({ default: "" })
    private tag!: string;

    // not sure how to do enums in Vue props - so just using string (need to convert)
    @Prop({ required: true })
    private category!: string;

    private chosenFile: File|null = null;

    //private documentToAdd: ModelDocument = new ModelDocument();

    private get noFileUrl(): string {
        return apiClient.resolveUrl("api/file/icon?extension=nul");
    }

    private get areNoFiles(): boolean {
        return this.documents.length === 0;
    }

    private get responseSetID(): string {
        return this.tag.indexOf("response-id=") > -1 ? this.tag.substring(this.tag.indexOf("response-id=") + 12) : "0";
    }

    private isUploading: boolean = false;

    private iconUrl(document: ModelDocument): string {
        return utils.iconUrl(document);
    }

    private getKey(document: ModelDocument): string {
        // if multiple new documents, ID on its own is no good as unique id
        return 'doc' + document.id ? document.id : document.originalFilename;
    }

    // Fires when file is chosen by user
    async onFileInput() {
        if(!this.chosenFile) {
            //toastr.info("No file chosen");
            return; 
        }                
        const formData = new FormData();
        formData.append("formFile", this.chosenFile);
        formData.append("responseSetID", this.responseSetID)
        
        this.isUploading = true;

        try {
            const response: IFileUploadResponse = await apiClient.postNonJson("/api/file/upload", formData);

            if (!response.success) {
                this.isUploading = false;
                toastr.error(response.errorMessage, "Upload failed");
                return;
            }

            const documentToAdd: ModelDocument = new ModelDocument();

            documentToAdd.httpContentType = response.httpContentType;
            documentToAdd.originalFilename = response.originalFilename;
            documentToAdd.filename = response.filename;
            documentToAdd.size = response.size;
            documentToAdd.category = utils.parseEnum(DocumentCategory, this.category);
            documentToAdd.uploadedDate = new Date();

            // can't mutate prop here - need to raise event...
            // this.documents.push(documentToAdd);

            // just a cosmetic delay
            setTimeout(() => {
                this.isUploading = false;
                this.chosenFile = null;
            }, 300);
            
            this.$emit("uploaded", documentToAdd, this.tag);
        } 
        catch (error) {
            this.isUploading = false;
            toastr.error("Upload failed");
        }        
    }

    async remove(doc: ModelDocument) {
        if (!this.canDelete) return;
        //this.documents = this.documents.filter(d => d.filename !== doc.filename);

        // can't mutate prop here - need to raise event...
        this.$emit("remove", doc, this.tag);
    }

    async download(doc: ModelDocument) {
        fileDownload.download(doc);
    }

}
