
    import Vue from "vue";
    import Component from "vue-class-component";
    import apiClient from "@/stuff/ApiClient";
    import utils from "@/stuff/Utils";
    import { Supplier as ModelSupplier } from "@/model/Supplier";
    import Details from "@/components/SupplierZoneSupplierTabs/Details.vue";
    import BuyersAndRegions from "@/components/SupplierZoneSupplierTabs/BuyersAndRegions.vue";
    import Communications from "@/components/SupplierZoneSupplierTabs/Communications.vue";
    import Documents from "@/components/SupplierZoneSupplierTabs/Documents.vue";
    import Payments from "@/components/AdminSupplierTabs/Payments.vue";
    import Certificates from "@/components/AdminSupplierTabs/Certificates.vue";
    import Contacts from "@/components/AdminSupplierTabs/Contacts.vue";
    import Users from "@/components/AdminSupplierTabs/Users.vue";

    @Component({ 
        components: {
            Details,
            BuyersAndRegions,
            Communications,
            Documents,
            Payments,
            Certificates,
            Contacts,
            Users
        } 
    })
    export default class Supplier extends Vue {

        mounted() {
            this.supplierID = +this.$store.state.signedInUser.supplierID;
            this.load(this.supplierID);
        }

        private supplierID: number = 0;
        private readonly supplier = new ModelSupplier();

        private get title(): string {
            return this.supplier.id ? this.supplier.name : "Supplier";
        }

        async load(supplierID: number) {
            if(supplierID > 0) {
                const serverSupplierData = await apiClient.get(`api/supplier/Load?id=${supplierID}`);
                utils.resetObject(this.supplier);
                // nb - update maps suppliers and contacts too
                this.supplier.update(serverSupplierData);
            }
            else {
                utils.resetObject(this.supplier);
            }
        }
        
    }
