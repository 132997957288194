
    import Vue from "vue";
    import Component from "vue-class-component";
    import apiClient from "@/stuff/ApiClient";
    import store from "@/store/store";
    import { TriState } from "@/model/Enums";
    import { IRegionSupplier, RegionSupplier } from "@/model/RegionSupplier";
    import { RegionSupplierSearchParameters } from "@/model/RegionSupplierSearchParameters";
    import { BuyerSupplier, IBuyerSupplier } from "@/model/BuyerSupplier";
    import { BuyerSupplierSearchParameters } from "@/model/BuyerSupplierSearchParameters";
    import { RegionLookupItem } from '@/model/RegionLookupItem';
    import { LookupItem } from '@/model/LookupItem';

    @Component({})
    export default class BuyersAndRegions extends Vue {

        mounted() {
            this.supplierID = +this.$store.state.signedInUser.supplierID;
            store.dispatch("loadBuyerList");
            this.loadRegionList();        
            this.load();
        }

        private supplierID: number = 0;
        private isWaiting: boolean = true;
        private regionList: Array<RegionLookupItem> = [];
        private get buyerList(): Array<LookupItem> { return this.$store.state.buyerList; }
        private buyerSupplierList: Array<IBuyerSupplier> = [];
        private regionSupplierList: Array<IRegionSupplier> = [];

        get hasSupplierPays(): boolean {
            return this.buyerSupplierList.filter(bs => !bs.doesBuyerPay).length > 0
        }

        async load() {
            this.buyerSupplierList = [];
            this.regionSupplierList = [];
            await this.refreshBuyerSuppliers();
            await this.refreshRegionSuppliers();
        }

        async loadRegionList() {
            this.regionList = await apiClient.get("api/region/lookups");
        }

        async refreshBuyerSuppliers() {
            const parameters = new BuyerSupplierSearchParameters();
            parameters.supplierID = this.supplierID;
            parameters.isDeleted = TriState.False;
            parameters.isSupplierZone = true;
            this.isWaiting = true;
            const responseData = await apiClient.post("api/buyerSupplier/search", parameters);
            this.buyerSupplierList = responseData.list.map((b: IBuyerSupplier) => new BuyerSupplier(b));
            this.$emit("buyerCountChanged", responseData.count); // update tab count badge in parent
            this.isWaiting = false;
        }

        async refreshRegionSuppliers() {
            const parameters = new RegionSupplierSearchParameters();
            parameters.supplierID = this.supplierID;
            parameters.isDeleted = TriState.False;
            this.isWaiting = true;
            const responseData = await apiClient.post("api/regionSupplier/search", parameters);
            this.regionSupplierList = responseData.list.map((r: IRegionSupplier) => new RegionSupplier(r));
            this.$emit("regionCountChanged", responseData.count); // update tab count badge in parent
            this.isWaiting = false;
        }
    }
