

    import Vue from "vue";
    import Component from "vue-class-component";
    import FileUpload from "@/components/FileUpload.vue";
    import fileDownload from "@/stuff/FileDownload";
    import apiClient from "@/stuff/ApiClient";
    import utils from "@/stuff/Utils";
    import store from "@/store/store";
    import { Document as ModelDocument } from "@/model/Document";
    import { ISupplierDocument, SupplierDocument } from "@/model/SupplierDocument";
    import { SupplierDocumentSearchParameters } from "@/model/SupplierDocumentSearchParameters";
    import { DocumentVisibility } from "@/model/Enums";

    @Component({
        components: { FileUpload }
    })
    export default class Documents extends Vue {

        mounted() {
            this.searchParameters.visibilities = [DocumentVisibility.Everyone, DocumentVisibility.Suppliers];
            this.searchParameters.supplierID = store.state.signedInUser == null ? 0 : +store.state.signedInUser.supplierID;
            this.load();
        }

        private isWaiting: boolean = true;

        private readonly searchParameters = new SupplierDocumentSearchParameters();
        private supplierDocumentList: Array<ISupplierDocument> = [];

        //
        // -- methods
        //

        async load() {
            this.supplierDocumentList = [];
            await this.refreshSupplierDocuments();
        }

        async refreshSupplierDocuments() {
            this.isWaiting = true;
            const responseData = await apiClient.post("api/supplierDocument/search", this.searchParameters);
            this.supplierDocumentList = responseData.map((c: ISupplierDocument) => new SupplierDocument(c));
            this.isWaiting = false;
        }

        private iconUrl(document: ModelDocument): string {
            return utils.iconUrl(document);
        }

        async download(doc: ModelDocument) {
            fileDownload.download(doc);
        }
    }
